import { Component, OnInit } from '@angular/core';
import { UploadFileService } from '../service/upload/upload-file.service';
import { async } from '@angular/core/testing';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  private filename = '1665874588985.png';

  constructor(private uploadFileService: UploadFileService) { }

  ngOnInit() {
  }

  getImageUrl(){
    return this.uploadFileService.getImageUrl('/download/images/',this.filename);
  }

}
