import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    // NgxPaginationModule
  ],
  exports: [
    NgxPaginationModule
  ]
})
export class ShareModuloModule { }
